/* eslint-disable max-len */
import * as styles from './oznameni-akcionarum.module.css';

const Notification = () => (
    <div className={styles.wrapper}>
        <h1> ROZHODNUTÍ O PŘEMĚNĚ LISTINNÝCH AKCIÍ NA JMÉNO NA ZAKNIHOVANÉ AKCIE A VÝZVA K ODEVZDÁNÍ AKCIÍ A SDĚLENÍ MAJETKOVÉHO ÚČTU</h1>
        <p>V Praze dne 21. května 2020</p>
        <p>
            Digital Vision Czech Republic, a.s.<br/>
            Sídlo: Žerotínova 1133/32, 130 00 Praha 3 Žižkov<br/>
            IČO: 031 77 238<br/>
            společnost zapsána v obchodním rejstříku vedeném Městským soudem v Praze pod sp. zn. B 19855<br/>
            (dále jen »Společnost«)
        </p>
        <p>
            tímto v souladu s § 529 a násl. zák. č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů, oznamuje, že jediný akcionář učinil dne 21.5.2020 rozhodnutí, kterým rozhodl o změně podoby všech akcií emitovaných Společností z listinné podoby na podobu zaknihovanou, a to ve formě notářského zápisu sepsaného Mgr. Šárkou Sýkorovou, notářkou se sídlem v Praze, pod č. NZ 465/2020 (dále jen »Rozhodnutí«).
        </p>
        <p >Společnost na základě shora uvedeného Rozhodnutí vyzývá akcionáře Společnosti k odevzdání všech listinných akcií na jméno emitovaných Společností za účelem jejich zaknihování, a to na adrese sídla Společnosti ve lhůtě dvou (2) měsíců ode dne následujícího po dni zveřejnění této výzvy v Obchodním věstníku a dále ke sdělení čísla účtu v příslušné evidenci, na který mají být akcie zaevidovány.
        </p>
        <p>Digital Vision Czech Republic, a.s.</p>
        <p>
            Mgr. Roman Dušek, člen představenstva<br/>
            Mgr. Diana Novotná, člen představenstva<br/>
            Libor Olexa, člen představenstva<br/>
        </p>
    </div>
);

export default Notification;
